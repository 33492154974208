import React from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import styles from "../utils/upload.module.scss"

const UploadErrorNotification = ({ fileName, message }) => (
  <div className="mt-2 has-text-danger has-text-centered is-white is-size-6 has-border-left-danger">
    <article className="message is-danger">
      <div className="message-body has-text-weight-bold">
        <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
        {fileName && (
          <span
            className={classNames(
              "has-text-weight-bold is-italic",
              styles["uploadFileName"]
            )}
          >
            {fileName}
          </span>
        )}{" "}
        {message}
      </div>
    </article>
  </div>
)

export default UploadErrorNotification
