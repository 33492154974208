import moment from "moment"

export const isFutureDate = ({ date }) => {
  let dateToday = new Date()
  dateToday.setDate(dateToday.getDate())

  if (date) return dateToday <= moment(date).toDate()
  return false
}

export const formatDate = ({ date, format }) => {
  return moment(
    new Date(
      `${date?.month?.label} ${
        date?.date?.label ? `${date?.date?.label}, ` : ""
      }${date?.year ? `${date?.year?.label}` : ""}`
    )
  ).format(format)
}
