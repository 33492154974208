import React from "react"
import classNames from "classnames"

import ReceiptUploadDropzoneBody from "./ReceiptUploadDropzoneBody.js"
import DropzoneLabel from "./UploadDropzoneLabel"

import styles from "../utils/upload.module.scss"

const ReceiptUploadDropzone = ({
  fileInputRef,
  filesUploaded = [],
  handleFileChooser,
  handleFileRead,
  notifications = [],
  icon,
  label
}) => {
  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/*, .pdf"
        value=""
      />

      {notifications.length > 0 && notifications.map((item) => item)}
      <div
        onClick={handleFileChooser}
        className={classNames(styles["dropzone__container"])}
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter") handleFileChooser()
        }}
      >
        <ReceiptUploadDropzoneBody
          icon={icon}
        />
        <DropzoneLabel label={label}/>
      </div>
      
    </div>
  )
}

export default ReceiptUploadDropzone
