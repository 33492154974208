import React, { useContext, useEffect } from "react"
import classNames from "classnames"

import ReceiptLayout from "../../../../Layout/ReceiptLayout"

import styles from "../utils/encode.module.scss"

import { FlexmedReimburseContext } from "../../../FlexmedContext/FlexmedReimburseContext"
import ReimbursementsListRow from "./utils/ReimbursementsListRow"
import ReimbursementsListRowMobile from "./utils/ReimbursementsListRowMobile"

const ReimbursementsList = ({
  handleClickAddReceipt,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
  pageContext,
}) => {
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )
  const { receipts } = flexmedReimburseState

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
  }, [])

  return (
    <ReceiptLayout
      isCentered
      desktop={10}
      fullhd={10}
      next={{
        label: "Next: " + pageContext.module.next,
        disabled: !receipts.length,
        link: pageContext?.nextPath,
      }}
      back={{ label: "Back", link: pageContext?.backPath }}
    >
      <div className="is-hidden-desktop is-hidden-tablet">
        {/*add receipt button for mobile*/}
        <button
          type="button"
          className="button is-fullwidth mb-1 has-text-weight-bold is-orange"
          onClick={handleClickAddReceipt}
        >
          + Add New Receipt
        </button>

        {/* reimubrsements list mobile */}
        <div className="mt-2 mb-5">
          {receipts.map((receipt, index) => (
            <ReimbursementsListRowMobile
              receipt={receipt}
              index={index}
              handleClickEditReceipt={handleClickEditReceipt}
              handleClickRemoveReceipt={handleClickRemoveReceipt}
            />
          ))}
        </div>

        {/* no receipts added message mobile*/}
        {receipts.length === 0 ? (
          <div className="mb-4">
            <p className="has-text-grey has-text-centered">
              No receipts uploaded
            </p>
          </div>
        ) : null}
      </div>
      <div className="is-hidden-mobile">
        {/* reimbursements list header */}
        <div className={classNames(styles["encode__header"])}>
          <p className="has-text-weight-bold">Reimbursement List</p>
          <button
            type="button"
            className="button is-orange has-text-weight-bold"
            onClick={handleClickAddReceipt}
          >
            + Add New Entry
          </button>
        </div>
        {/* reimbursements list table */}
        <table
          className={classNames(
            styles["table"],
            "table is-fullwidth mt-1 mb-5"
          )}
        >
          <thead>
            <tr>
              <th>Preview</th>
              <th>Receipt Number</th>
              <th>Receipt Date</th>
              <th>Total Encoded</th>
              <th aria-label="Action Buttons"></th>
            </tr>
          </thead>
          <tbody>
            {receipts.map((receipt, index) => (
              <ReimbursementsListRow
                receipt={receipt}
                index={index}
                handleClickEditReceipt={handleClickEditReceipt}
                handleClickRemoveReceipt={handleClickRemoveReceipt}
              />
            ))}
          </tbody>
        </table>
        {/* no receipts added message */}
        {receipts.length === 0 ? (
          <div>
            <p className="has-text-grey pl-1">No receipts uploaded</p>
            <hr className="mt-1 has-background-light" />
          </div>
        ) : null}
      </div>
    </ReceiptLayout>
  )
}

export default ReimbursementsList
