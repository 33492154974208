import * as Yup from "yup"

const yearNow = new Date(Date.now()).getFullYear()

export const personalDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  employeeNumber: Yup.string()
    .min(5, "Employee number must have a minimum of 5 digits")
    .required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  // civilStatus: Yup.string().required("This field is required"),
  reimbursementMethod: Yup.object().shape({
    reimbursingFor: Yup.string().required("This field is required"),
    reimbursingForName: Yup.string().when("reimbursingFor", {
      is: (value) => value.includes("Dependent"),
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string(),
    }),
  }),
})

export const personalDetailsWithBeneficiaryValidationSchema = Yup.object().shape(
  {
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    employeeNumber: Yup.string()
      .min(5, "Employee number must have minimum of 5 digits")
      .matches(/^[a-zA-Z]{3}\d{3}$/, "Invalid employee number format.")
      .required("This field is required"),
    mobileNumber: Yup.string()
      .min(11, "Please input a valid mobile number in this format: 09991234567")
      .max(11, "Please input a valid mobile number in this format: 09991234567")
      .matches(
        /^[0-9]*$/,
        "Please input a valid mobile number in this format: 09991234567"
      )
      .required("This field is required"),
    emailAddress: Yup.string()
      .email("Please input a valid email")
      .required("This field is required"),
    civilStatus: Yup.string().required("This field is required"),
    beneficiaryName: Yup.string().required("This field is required"),
    beneficiaryRelationship: Yup.string().required("This field is required"),
  }
)

export const receiptItemsValidationSchema = Yup.object().shape({
  receiptNumber: Yup.string().required("This field is required"),
  pharmacyName: Yup.string().required("This field is required"),
  dateOfPurchase: Yup.object({
    month: Yup.string().required("This field is required"),
    date: Yup.string().required("This field is required"),
    year: Yup.object().shape({
      value: Yup.number()
        .min(yearNow - 1, "Please input a valid year")
        .max(yearNow, "Please input a valid year")
        .required("This field is required"),
    }),
  }),
  items: Yup.array().of(
    Yup.object().shape({
      code: Yup.string().required("Please select your purchased item"),
      itemPrice: Yup.number()
        .min(0.01, "Please enter a valid subtotal")
        .required("This field is required"),
    })
  ),
})

export const personalDetailsInitialValues = {
  fullName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
}

export const encodeReceiptInitialValues = {
  receiptNumber: "",
  pharmacyName: "",
  dateOfPurchase: {
    month: "",
    date: "",
    year: "",
  },
  items: [],
}

export const storeOptions = [
  {
    value: "Mercury Drug",
    label: "Mercury Drug",
  },
  {
    value: "Watsons Drug",
    label: "Watsons Drug",
  },
  {
    value: "The Generics Pharmacy",
    label: "The Generics Pharmacy",
  },
  {
    value: "St. Joseph Drugstore",
    label: "St. Joseph Drugstore",
  },
  {
    value: "De Guzman Drug",
    label: "De Guzman Drug",
  },
  {
    value: "Blessed Sacrament Pharmacy",
    label: "Blessed Sacrament Pharmacy",
  },
  {
    value: "Curamed Pharmacy",
    label: "Curamed Pharmacy",
  },
  {
    value: "ExpressAid Pharmacy",
    label: "ExpressAid Pharmacy",
  },
  {
    value: "Generika Drugstore",
    label: "Generika Drugstore",
  },
  {
    value: "Rose Pharmacy",
    label: "Rose Pharmacy",
  },
  {
    value: "JKB Pharmacy",
    label: "JKB Pharmacy",
  },
  {
    value: "South Star Drug",
    label: "South Star Drug",
  },
  {
    value: "MedExpress",
    label: "MedExpress",
  },
  {
    value: "Zure Rx Drug",
    label: "Zure Rx Drug",
  },
  {
    value: "Central Luzon Drug",
    label: "Central Luzon Drug",
  },
  {
    value: "St. Ilian's Pharmacy",
    label: "St. Ilian's Pharmacy",
  },
  {
    value: "Farmacia Sta. Monica",
    label: "Farmacia Sta. Monica",
  },
  {
    value: "Drugman Drughouse",
    label: "Drugman Drughouse",
  },
  {
    value: "Biochemist Drug",
    label: "Biochemist Drug",
  },
  {
    value: "Orion Drug",
    label: "Orion Drug",
  },
  {
    value: "Other drugstore",
    label: "Other drugstore",
  },
  {
    value: "Other medical center",
    label: "Other medical center",
  },
]
