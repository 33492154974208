import { useStaticQuery, graphql } from "gatsby"

const ALL_FLEXMED_REIMBURSE_PRODUCTS = graphql`
  query {
    allAirtableallSitelFmrProducts(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          productTitle: ProductTitle
          code: ItemCode
          strength: Strength
          form: Form
          molecule: Molecule
          brand: Brand
        }
      }
    }
  }
`

export const useFmrProducts = () => {
  const data = useStaticQuery(ALL_FLEXMED_REIMBURSE_PRODUCTS)

  return data
}
