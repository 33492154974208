import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"

import Container from "../../../../Layout/Container"
import ReceiptLayout from "../../../../Layout/ReceiptLayout"
import UploadReceipt from "./EncodeReceiptsComponents/UploadReceipt"
import EncodeReceiptDetails from "./EncodeReceiptsComponents/EncodeReceiptDetails"
// import EncodeReceiptItems from "./EncodeReceiptsComponents/EncodeReceiptItems"
import EncodeReceiptLineItems from "./EncodeReceiptsComponents/EncodeReceiptLineItems"

import {
  receiptItemsValidationSchema,
  encodeReceiptInitialValues,
} from "../utils/formData"
import { isFutureDate } from "../../../../../services/date"
import { FlexmedReimburseContext } from "../../../FlexmedContext/FlexmedReimburseContext"

const EncodeReceiptForm = ({
  handleClickBack,
  handleSubmitReceipt,
  type,
  activeIndex,
}) => {
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
  }, [])

  const { receipts } = flexmedReimburseState
  const activeReceipt = { ...receipts[activeIndex] }
  const initialFilesUploaded =
    type === "add" ? [] : receipts[activeIndex]?.uploads
  const initialReceiptValues =
    type === "add" ? { ...encodeReceiptInitialValues } : activeReceipt
  const [filesUploaded, setFilesUploaded] = useState(initialFilesUploaded)

  const handleSubmit = (values, { setErrors, resetForm }) => {
    let dateOfPurchase = values?.dateOfPurchase
    dateOfPurchase = `${dateOfPurchase?.month?.value} ${dateOfPurchase?.date?.value} ${dateOfPurchase?.year?.value}`

    if (!!isFutureDate({ date: dateOfPurchase })) {
      setErrors({
        dateOfPurchase: {
          month: { value: "Please enter a valid date." },
          date: { value: "Please enter a valid date." },
        },
      })
      return values
    }

    if (type === "add") {
      flexmedReimburseDispatch({
        type: "SAVE_RECEIPTS",
        payload: [...receipts, { ...values, uploads: filesUploaded }],
      })
      flexmedReimburseDispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...flexmedReimburseState,
          ...values,
          receipts: [...receipts, { ...values, uploads: filesUploaded }],
        },
      })
    } else {
      let currentReceipts = receipts
      currentReceipts.splice(activeIndex, 1, {
        ...values,
        uploads: filesUploaded,
      })
      flexmedReimburseDispatch({
        type: "SAVE_RECEIPTS",
        payload: [...currentReceipts],
      })
      flexmedReimburseDispatch({
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: {
          ...flexmedReimburseState,
          ...values,
          receipts: [...currentReceipts],
        },
      })
    }
    resetForm()
    if (handleSubmitReceipt) handleSubmitReceipt()
  }

  return (
    <Container isCentered desktop={10} fullhd={8}>
      <Formik
        enableReinitialize
        initialValues={initialReceiptValues}
        onSubmit={handleSubmit}
        validationSchema={receiptItemsValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off">
            <ReceiptLayout
              isCentered
              desktop={8}
              fullhd={8}
              submit={{
                label: "Save Receipt",
                Next: "Save Receipt",
                disabled:
                  !filesUploaded.length ||
                  !values.items.length ||
                  !values.dateOfPurchase.date.value,
              }}
              back={{ label: "Back", callback: handleClickBack }}
            >
              <div className="mb-3">
                <UploadReceipt
                  filesUploaded={filesUploaded}
                  setFilesUploaded={setFilesUploaded}
                />
                <EncodeReceiptDetails values={values} />
                <EncodeReceiptLineItems
                  parentSetFieldValue={setFieldValue}
                  items={values.items}
                />
              </div>
            </ReceiptLayout>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default EncodeReceiptForm
