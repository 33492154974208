import React, { Fragment, useState, useRef, useContext, useEffect } from "react"

import DocumentsCardRow from "../../../../../Cart/DocumentsCard/DocumentsCardRow"
import UploadGuidelines from "../../../../../Upload/UploadGuidelines"
import guidelines from "../../utils/uploadGuidelines.json"
import { AppContext } from "../../../../../../context/AppContext"
import { FlexmedReimburseContext } from "../../../../FlexmedContext/FlexmedReimburseContext"

import ReceiptUploadDropzone from "../../../../../Upload/UploadDropzone/ReceiptUploadDropzone"
import UploadErrorNotification from "../../../../../Upload/UploadDropzone/UploadErrorNotification"
import { FmrSection } from "../../../../../Elements/Section"

import { isIeOrEdge } from "../../../../../../services/browserCompatibility"
import { uploadDocument } from "../../../../../Upload/services/uploadDocuments"

import useEpharmacyIcon from "../../hooks/useEpharmacyIcon"
import acceptedFileTypes from "../../utils/acceptedFileTypes.json"

const MAX_FILE_SIZE_IN_BYTES = 4000000
const MAX_FILE_COUNT = 3
const isFullSize = false

const UploadReceipt = ({ filesUploaded, setFilesUploaded }) => {
  const [notifications, setNotifications] = useState([])
  const fileInputRef = useRef(null)
  const { state, dispatch } = useContext(AppContext)
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )
  const data = useEpharmacyIcon()
  const epharmacyIcon = data?.document?.childImageSharp?.fixed

  const handleCloseModal = async () => {
    dispatch({
      type: "HIDE_MODAL",
    })
    dispatch({ type: "CLOSE_SLIDING_MODAL" })
    await delay(400)
    dispatch({ type: "HIDE_SLIDING_MODAL" })
    setTimeout(() => handleFileChooser(), 500)
  }

  const showUploadGuidelines = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        content: (
          <UploadGuidelines
            guidelines={guidelines}
            handleCloseModal={handleCloseModal}
          />
        ),
        heading: "Upload Guidelines",
        headerClass:
          "has-text-info has-background-info-light has-text-weight-bold is-size-4 has-text-centered",
        hideCloseButton: true,
        isCard: true,
        modalBackground: "green",
        hideOnMobile: true,
      },
    })

    dispatch({
      type: "SHOW_SLIDING_MODAL",
      payload: {
        content: (
          <UploadGuidelines
            guidelines={guidelines}
            handleCloseModal={handleCloseModal}
          />
        ),
        heading: "Upload Guidelines",
        headerClass:
          "has-text-info has-text-weight-bold is-size-4 has-text-centered",
        hideCloseButton: true,
        isCard: true,
        closeOnClickOutside: true,
      },
    })
  }

  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
  }, [])

  const handleDeleteDocument = (index) => {
    let tempFilesUploaded = [...filesUploaded]
    tempFilesUploaded.splice(index, 1)
    setFilesUploaded(tempFilesUploaded)
  }

  const openFileChooser = () => {
    if (filesUploaded.length < 1) {
      showUploadGuidelines()
    } else {
      if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
      else handleFileChooser()
    }
  }

  const handleAlreadyUploaded = (setNotifications) => (file) => {
    setNotifications((notificationsList) => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const checkInvalidFileType = ({ filesUploaded }) => {
    return filesUploaded.every((file) => {
      return !acceptedFileTypes.includes(file.type)
    })
  }

  const checkInvalidFileTypes = ({ fileUploaded }) => {
    return !acceptedFileTypes.includes(fileUploaded.type)
  }

  const handleFileRead = (event) => {
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    const hasInvalidFileType = checkInvalidFileType({
      filesUploaded: tempFilesUploaded,
    })
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else if (hasInvalidFileType) {
      initialNotifications.push(
        <UploadErrorNotification
          message={`You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
        />
      )
    } else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (
          checkInvalidFileTypes({
            fileUploaded: tempFilesUploaded[i],
          })
        )
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message={`has an invalid file type. You may only upload files in the following format: jpeg, jpg, png, or pdf files.`}
            />
          )
        if (
          tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES &&
          !checkInvalidFileTypes({
            fileUploaded: tempFilesUploaded[i],
          })
        )
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            setFilesUploaded,
            null,
            handleAlreadyUploaded(setNotifications),
            null,
            flexmedReimburseState,
            dispatch,
            i,
            flexmedReimburseDispatch,
            "encode"
          )
        else if (tempFilesUploaded[i].size > MAX_FILE_SIZE_IN_BYTES)
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 4MB. Please upload a file or photo less than 4MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  return (
    <Fragment>
      <FmrSection
        addOns={{ left: 1 }}
        title="Upload Receipt"
        className="mt-1 mb-4"
        isFmr
      >
        {/* <UploadGuidelines guidelines={guidelines} /> */}
        {filesUploaded.length < 3 ? (
          <ReceiptUploadDropzone
            fileInputRef={fileInputRef}
            handleFileChooser={openFileChooser}
            filesUploaded={filesUploaded}
            handleFileRead={handleFileRead}
            notifications={notifications}
            icon={epharmacyIcon}
            label="Upload Receipt"
          />
        ) : null}
        <div className="mb-1">
          {filesUploaded?.map((file, index) => (
            <DocumentsCardRow
              fileName={file.name}
              oldFileName={file.oldname}
              index={index}
              handleDeleteDocument={handleDeleteDocument}
              file={file}
              isFullSize={isFullSize}
              showOldFileName
              showDeleteButton
              isClickable
            />
          ))}
        </div>
      </FmrSection>
    </Fragment>
  )
}

export default UploadReceipt
