import React, { Fragment, useContext } from "react"
import { ErrorMessage, Formik, Form } from "formik"

import { FlexmedEncodeReceiptCart } from "../../../../Cart"
import ReceiptItemModal from "./modals/ReceiptItemModal"
import { FmrSection } from "../../../../../Elements/Section"
import Button from "../../../../../Elements/Button"
import FormInput from "../../../../../Elements/Form/FormInput"
import ReceiptItemSearch from "./modals/ReceiptItemSearch"

import { useFmrProducts } from "../../hooks/useFmrProducts"
import { receiptItemModalValidationSchema } from "./modals/receiptItemModalValidationSchema"

import { AppContext } from "../../../../../../context/AppContext"

const EncodeReceiptLineItems = ({ parentSetFieldValue, items }) => {
  const { dispatch } = useContext(AppContext)

  const data = useFmrProducts()
  let dataMap = data.allAirtableallSitelFmrProducts.nodes.map(
    (node) => node.data
  )
  dataMap = dataMap.map(reformatAirtableData)

  const showModal = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Encode Line Item",
        isCard: true,
        headerClass: `has-text-primary has-background-light`,
        content: (
          <ReceiptItemModal dataMap={dataMap} handleSubmit={handleSubmit} />
        ),
      },
    })
  }

  const handleSubmit = (values) => {
    // get item details based on field
    let item = {
      SKU: values.productTitle,
      code: values.code,
      itemPrice: values.lineItem,
      strength: values.strength,
      molecule: values.molecule,
      form: values.form,
      brand: values.brand,
    }

    let tempItems = [...items, item]
    parentSetFieldValue("items", tempItems)
    dispatch({ type: "HIDE_MODAL" })
  }

  const totalItems = !items?.length ? "" : `Total Items: ${items.length}`
  let maxReceiptAmount = 100000
  return (
    <Fragment>
      <FmrSection
        title="Add Items"
        addOns={{
          left: 3,
          right: totalItems,
        }}
        className="mt-1 mb-4"
        isFmr
      >
        {!items?.length ? (
          // if no items yet, put search bar, form input for line item, button to add item
          <Formik
            initialValues={{ lineItem: "" }}
            validationSchema={receiptItemModalValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form autoComplete="off">
                  <div>
                    <label
                      htmlFor="receiptItemSearch"
                      className="label has-text-weight-normal has-text-left"
                    >
                      Search Medicines
                    </label>
                    <label className="label is-size-7 has-text-weight-normal has-text-left">
                      Select "Other Medicines" if you can't find the exact
                      medicine you purchased.
                    </label>
                    <ReceiptItemSearch
                      setFieldValue={setFieldValue}
                      dataMap={dataMap}
                    />
                    <div className="helper has-text-danger mb-1">
                      <ErrorMessage name="code" />
                    </div>
                    <FormInput
                      name="lineItem"
                      label="Line Total"
                      type="number"
                      className="has-text-left"
                      step="0.01"
                      placeholder="123.50"
                      maxLength={6}
                      max={maxReceiptAmount}
                      isRequired
                      hideOptional
                      hasAddons={true}
                      onChange={(event) => {
                        if (event.target.value > maxReceiptAmount)
                          setFieldValue("lineItem", "100000")
                      }}
                      addonLeft={<Button className="is-static"> PHP </Button>}
                    />

                    <button
                      type="submit"
                      className="button is-fullwidth is-orange has-text-weight-bold mt-2 mb-2"
                      disabled={!values.lineItem || !values.productTitle}
                    >
                      Add Item
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        ) : (
          // if there are items, return the usual view with cart + add more items
          <span>
            <FlexmedEncodeReceiptCart
              open={{
                medicinesCard: true,
              }}
              items={items}
              setFieldValue={parentSetFieldValue}
            />
            <button
              type="button"
              className="button px-0 is-text has-text-primary has-text-weight-bold is-underlined"
              onClick={showModal}
            >
              + Add more items
            </button>
          </span>
        )}
      </FmrSection>
    </Fragment>
  )
}

const reformatAirtableData = (dataMapElement) => {
  let jsonFormat = {
    productTitle: dataMapElement?.productTitle,
    code: dataMapElement.code?.[0],
    strength: dataMapElement?.strength?.[0],
    form: dataMapElement?.form?.[0],
    molecule: dataMapElement?.molecule?.[0],
    brand: dataMapElement?.brand?.[0],
  }

  return jsonFormat
}

export default EncodeReceiptLineItems
