import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "layout"
import ReimbursementsList from "./views/ReimbursementsList"
import EncodeReceiptForm from "./views/EncodeReceiptForm"
import { FlexmedReimburseContext } from "../../FlexmedContext/FlexmedReimburseContext"
import { getContextFromSession } from "../../../../context/services/context"

import { isObjectEmpty } from "../../../../services/general"

const EncodeReceipt = ({ pageContext }) => {
  const [currentView, setCurrentView] = useState("view")
  const [activeIndex, setActiveIndex] = useState({})
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )

  const state = !isObjectEmpty(getContextFromSession())
    ? getContextFromSession()
    : flexmedReimburseState

  // receipts object from state
  const { receipts } = state

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
  }, [])

  // decide which view to use if empty receipts
  useEffect(() => {
    if (receipts.length < 1) setCurrentView("add")
  }, [receipts])

  const viewReceipts = () => {
    setCurrentView("view")
    window.scrollTo(0, 0)
  }

  const handleSubmitReceipt = () => {
    viewReceipts()
  }

  const handleClickBack = () => {
    if (receipts.length < 1) {
      navigate(pageContext?.backPath)
    } else {
      viewReceipts()
    }
  }

  const handleClickEditReceipt = ({ index }) => {
    setActiveIndex(index)
    setCurrentView("edit")
  }

  const handleClickRemoveReceipt = ({ index }) => {
    let currentReceipts = [...receipts]
    currentReceipts.splice(index, 1)

    flexmedReimburseDispatch({
      type: "SAVE_RECEIPTS",
      payload: [...currentReceipts],
    })
    flexmedReimburseDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...flexmedReimburseState,
        receipts: [...currentReceipts],
      },
    })
  }
  if (currentView === "view") {
    return (
      <Layout
        {...pageContext?.module}
        currentStep={2}
        totalSteps={3}
        hasMobileStepper={true}
        subtitle={
          <span>
            Click '<span className="has-text-primary">Add New Entry</span>' for{" "}
            <strong>each</strong> receipt you want to get reimbursed.
          </span>
        }
      >
        <ReimbursementsList
          handleClickAddReceipt={() => setCurrentView("add")}
          handleClickEditReceipt={handleClickEditReceipt}
          handleClickRemoveReceipt={handleClickRemoveReceipt}
          pageContext={pageContext}
        />
      </Layout>
    )
  } else if (currentView === "add" || currentView === "edit") {
    return (
      <Layout
        {...pageContext?.module}
        currentStep={2}
        totalSteps={3}
        hasMobileStepper={true}
        subtitle={
          <span>
            Click '<span className="has-text-primary">Add New Entry</span>' for{" "}
            <strong>each</strong> receipt you want to get reimbursed.
          </span>
        }
      >
        <EncodeReceiptForm
          handleClickBack={handleClickBack}
          handleSubmitReceipt={handleSubmitReceipt}
          type={currentView}
          activeIndex={activeIndex}
        />
      </Layout>
    )
  }

  return null
}

export default EncodeReceipt
