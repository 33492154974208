import React from "react"
import { ErrorMessage, Formik, Form } from "formik"

import Button from "../../../../../../Elements/Button"
import FormInput from "../../../../../../Elements/Form/FormInput"
import ReceiptItemSearch from "./ReceiptItemSearch"

import { receiptItemModalValidationSchema } from "./receiptItemModalValidationSchema"

const ReceiptItemModal = ({ dataMap, handleSubmit }) => {
  let maxReceiptAmount = 100000
  return (
    <Formik
      initialValues={{ lineItem: "" }}
      validationSchema={receiptItemModalValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form autoComplete="off">
            <div className="mx-4 mx-1-mobile">
              <label
                className="label has-text-weight-normal has-text-left"
                htmlFor="receiptItemSearch"
              >
                Search Medicines
              </label>
              <label className="label is-size-7 has-text-weight-normal has-text-left">
                Select "Other Medicines" if you can't find the exact medicine
                you purchased.
              </label>
              <ReceiptItemSearch
                setFieldValue={setFieldValue}
                dataMap={dataMap}
              />
              <div className="helper has-text-danger mb-1">
                <ErrorMessage name="code" />
              </div>
              <FormInput
                name="lineItem"
                label="Line Total"
                type="number"
                maxLength={6}
                className="has-text-left"
                step="0.01"
                placeholder="123.50"
                max={maxReceiptAmount}
                isRequired
                hideOptional
                hasAddons={true}
                onChange={(event) => {
                  if (event.target.value > maxReceiptAmount)
                    setFieldValue("lineItem", "100000")
                }}
                addonLeft={<Button className="is-static"> PHP </Button>}
              />

              <button
                type="submit"
                className="button is-fullwidth is-orange has-text-weight-bold mt-2 mb-2"
                disabled={!values.lineItem || !values.productTitle}
              >
                Add Item
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ReceiptItemModal
