import React from "react"

import Button from "../../Elements/Button"

const UploadGuidelines = ({ guidelines, handleCloseModal }) => {
  return (
    <div className="is-flex is-flex-direction-column mx-1 px-2 mt-1">
      <h3 className="mt-1 mb-2 has-text-info has-text-weight-bold is-hidden-desktop has-text-centered">
        Upload Guidelines
      </h3>
      <ol className="ml-1">
        {guidelines.map((item, index) => (
          <li className="has-text-left" key={index}>
            {item}
          </li>
        ))}
        <Button
          onClick={handleCloseModal}
          className="mt-2 is-hidden-mobile"
          color="orange"
        >
          I understand
        </Button>
        <Button
          onClick={handleCloseModal}
          className="mt-2 mb-2-mobile is-hidden-tablet is-hidden-desktop is-hidden-fullhd"
          color="orange"
          isFullwidth
        >
          I understand
        </Button>
      </ol>
    </div>
  )
}

export default UploadGuidelines
