import React, { Fragment } from "react"

import FormDate from "../../../../../Elements/Form/FormDate"
import FormInput from "../../../../../Elements/Form/FormInput"
import FormSelect from "../../../../../Elements/Form/FormSelect"
import { FmrSection } from "../../../../../Elements/Section"
import { storeOptions } from "../../utils/formData"

const EncodeReceiptDetails = ({ values }) => {
  let currentDate = new Date().toISOString().slice(0, 10)
  var pastDate = new Date()
  var year = pastDate.getFullYear()
  var month = pastDate.getMonth()
  var day = pastDate.getDate()
  pastDate = new Date(year - 1, month, day + 1).toISOString().slice(0, 10)
  return (
    <Fragment>
      <FmrSection
        addOns={{ left: 2 }}
        title="Input Receipt Details"
        className="mt-1 mb-4"
        isFmr
      >
        <FormInput
          name="receiptNumber"
          label="Receipt Number"
          isRequired
          placeholder="ABG11456789"
          maxLength={15}
        />
        <FormSelect
          name="pharmacyName"
          label="Store Name"
          placeholder="Select store"
          isRequired
          value={values?.pharmacyName}
          options={storeOptions}
        />

        <FormDate
          config={{
            range: {
              minDate: pastDate,
              maxDate: currentDate,
            },
          }}
          name="dateOfPurchase"
          values={values?.dateOfPurchase}
          isRequired
          label="Receipt Date"
        />
      </FmrSection>
    </Fragment>
  )
}

export default EncodeReceiptDetails
