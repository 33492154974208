import React from "react"
import classNames from "classnames"

import FileThumbnail from "./FileThumbnail"

import styles from "../../utils/encode.module.scss"

const ReceiptInfo = ({ label, value, className }) => (
  <p
    className={classNames(
      "",
      className || "",
      styles["encode__receipt_infoMobile"]
    )}
  >
    <span>{label}</span>
    <span>{value}</span>
  </p>
)

const ReimbursementsListRowMobile = ({
  receipt,
  index,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  const { receiptNumber, uploads, dateOfPurchase, items } = receipt
  const dateOfPurchaseString = `${dateOfPurchase?.month?.value} ${dateOfPurchase?.date?.value} ${dateOfPurchase?.year?.value}`
  const totalEncodedPriceString = items
    ?.reduce(
      (previousValue, currentValue) =>
        parseFloat(previousValue) + parseFloat(currentValue?.itemPrice),
      0
    )
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  return (
    <div className={classNames("card mb-2", styles["encode__receipt_card"])}>
      <div className="card-content">
        <p className="">Receipt No. {receiptNumber}</p>
        <hr className="has-background-light mt-1" />
        <div className="columns is-vcentered is-mobile">
          <div className="column is-3">
            <FileThumbnail
              isMobile
              path={
                uploads?.[0]?.oldname?.includes("pdf")
                  ? "pdf"
                  : uploads[0]?.path
              }
            />
          </div>
          <div className="column">
            <ReceiptInfo label="Date" value={dateOfPurchaseString} />
            <ReceiptInfo
              label="Total"
              value={`Php ${totalEncodedPriceString}`}
              className="has-text-weight-bold"
            />
            <p className="has-text-right">
              <span
                role="button"
                tabIndex={0}
                className="is-clickable has-text-primary has-text-weight-bold  mr-1"
                onClick={() => handleClickEditReceipt({ index })}
                onKeyDown={(event) => {
                  if (event.key === "Enter") handleClickEditReceipt({ index })
                }}
              >
                Edit
              </span>
              |
              <span
                role="button"
                tabIndex={0}
                className="is-clickable has-text-danger has-text-weight-bold  ml-1"
                onClick={() => handleClickRemoveReceipt({ index })}
                onKeyDown={(event) => {
                  if (event.key === "Enter") handleClickRemoveReceipt({ index })
                }}
              >
                Remove
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReimbursementsListRowMobile
