import React from "react"
import Search from "../../../../../../Search/Search"
import { useField } from "formik"

const ReceiptItemSearch = ({ dataMap, setFieldValue }) => {
  const FIELD_INDEX = 0
  const HELPERS_INDEX = 2
  const fieldData = useField({ name: `productTitle` })

  return (
    <Search
      name="productTitle"
      dataSource={{
        type: "flexmed",
        data: dataMap,
        lookupKeys: ["productTitle"],
      }}
      onSelect={(product) => {
        fieldData[HELPERS_INDEX].setValue(product.productTitle[0])
        setFieldValue("productTitle", product.productTitle[0])
        setFieldValue("code", product.code.toString())
        setFieldValue("strength", product.strength)
        setFieldValue("molecule", product.molecule)
        setFieldValue("form", product.form)
        setFieldValue("brand", product.brand)
      }}
      handleChange={() => {
        setFieldValue("code", "")
      }}
      placeholder="Metformin"
      withIcon
      module="flexmed"
      field={fieldData[FIELD_INDEX]}
      id="receiptItemSearch"
    />
  )
}

export default ReceiptItemSearch
