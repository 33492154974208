import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import styles from "../utils/upload.module.scss"

const ReceiptUploadDropzoneBody = ({ icon }) => (
  // eslint-disable-next-line
  <div
    className={classNames(
      "has-background-light mb-0 is-flex is-align-items-center is-justify-content-center",
      styles["dropzone__top"]
    )}
  >
    <p
      className={classNames(
        "title my-1 has-text-primary has-text-centered",
        styles["dropzone__uploadBox"]
      )}
    >
      <Img fixed={icon} alt="fileUpload" />
    </p>
  </div>
)

export default ReceiptUploadDropzoneBody
