import React from "react"
import FileThumbnail from "./FileThumbnail"

const ReimbursementsListRow = ({
  receipt,
  index,
  handleClickEditReceipt,
  handleClickRemoveReceipt,
}) => {
  const { receiptNumber, uploads, dateOfPurchase, items } = receipt
  const dateOfPurchaseString = `${dateOfPurchase?.month?.value} ${dateOfPurchase?.date?.value} ${dateOfPurchase?.year?.value}`

  const totalEncodedPriceString = items
    ?.reduce(
      (previousValue, currentValue) =>
        parseFloat(previousValue) + parseFloat(currentValue?.itemPrice),
      0
    )
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

  return (
    <tr>
      <td>
        <FileThumbnail
          path={
            uploads?.[0]?.oldname?.includes("pdf") ? "pdf" : uploads[0]?.path
          }
        />
      </td>
      <td>{receiptNumber}</td>
      <td>{dateOfPurchaseString}</td>
      <td>Php {totalEncodedPriceString}</td>
      <td>
        <div className="buttons">
          <button
            type="button"
            className="button is-small is-light has-text-primary"
            onClick={() => handleClickEditReceipt({ index })}
          >
            Edit
          </button>
          <button
            type="button"
            className="button is-small has-text-danger"
            onClick={() => handleClickRemoveReceipt({ index })}
          >
            Remove
          </button>
        </div>
      </td>
    </tr>
  )
}

export default ReimbursementsListRow
