import React from "react"
import classNames from "classnames"
import styles from "../utils/upload.module.scss"

const DropzoneLabel = ({ label, onClick, onKeyDown }) => {
  return (
    <div
      className={classNames(
        "has-background-primary mt-0 is-flex is-align-items-center is-justify-content-center",
        styles["dropzone__bottom"]
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <p className="has-text-white has-text-centered has-text-weight-bold">
        {label || "Upload Document"}
      </p>
    </div>
  )
}

export default DropzoneLabel
